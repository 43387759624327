import React, { useState } from "react"
import { Link } from "gatsby"
import YouTubeIcon from "./icons/youtube-icon"
import InstagramIcon from "./icons/instagram-icon"

const clickIcon = () => (
  <svg
    className="w-5 h-5 inline ml-2 -mt-0"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"></path>
  </svg>
)

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  let menuOptions = [
    {
      text: "Blog",
      url: "/blog/",
    },
    {
      text: "Printables",
      url: "/printables",
    },
    {
      text: "Homeschool Guide",
      url: "/ready-set-homeschool",
    },
  ]

  function handleMenuToggle() {
    setMenuOpen(!menuOpen)
  }

  return (
    <nav
      className="flex items-center justify-between flex-wrap px-4 
                    text-jnl-teal-medium
                    border border-b sticky top-0 bg-white z-50"
    >
      <Link
        to="/"
        className="block text-white flex items-center 
        flex-shrink-0 text-jnl-teal-medium w-36 mr-12"
      >
        <img
          className="m-0"
          src="/joyful-noise-learning-logo.svg"
          alt="Joyful Noise Learning Logo "
        />
      </Link>

      <div className="block lg:hidden">
        <button
          onClick={handleMenuToggle}
          className="flex items-center px-3 py-2 border rounded text-jnl-teal-medium border-teal-400 hover:text-jnl-teal-dark hover:border-jnl-teal-dark"
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto leading-8">
        {/* <!--Main Menu--> */}
        <div className="hidden lg:flex flex-grow space-x-2">
          {menuOptions.map(option => {
            return (
              <Link
                key={option.url}
                to={option.url}
                activeClassName="text-jnl-red-medium font-thin border-jnl-red-medium border-b-2"
                className="block text-sm lg:mt-2 py-2 lg:inline-block lg:mt-0 px-2 border-transparent border-b-2
                hover:text-jnl-teal-dark transition ease-in duration-300 uppercase font-light"
              >
                {option.text}
              </Link>
            )
          })}
        </div>
        <div className="hidden lg:block lg:inline-block border-transparent border-b-2 leading-none">
          <a
            className="block text-sm text-jnl-red-medium lg:mt-2 py-2 lg:inline-block lg:mt-0 px-4 mr-4
            hover:text-jnl-teal-dark transition ease-in duration-300 uppercase hover:underline font-thin"
            href="https://www.tryinteract.com/share/quiz/5eb07641bed8430014a171cd"
          >
            What is Your Homeschool Style?{clickIcon()}
          </a>
          <a
            href="https://www.youtube.com/c/joyfulnoiseliving"
            className="text-jnl-teal-medium hover:text-gray-700"
          >
            <YouTubeIcon size="6" />
          </a>
          <a
            href="https://instagram.com/joyfulnoisemama"
            className="text-jnl-teal-medium hover:text-gray-700"
          >
            <InstagramIcon size="6" />
          </a>
        </div>
        {/* <!--/Main Menu--> */}

        {/* <!--Mobile Menu--> */}
        <div
          className={`bg-gray-100 divide-y divide-gray-200 my-4 px-6 py-2 rounded ${
            menuOpen ? "visible" : "hidden"
          }`}
        >
          <Link
            to="/"
            onClick={handleMenuToggle}
            className="block text-jnl-teal-medium text-sm lg:mt-2 py-2 lg:inline-block lg:mt-0
            hover:text-jnl-teal-dark transition ease-in duration-300 uppercase font-light"
          >
            Home
          </Link>
          {menuOptions.map(option => {
            return (
              <Link
                key={option.url}
                to={option.url}
                onClick={handleMenuToggle}
                className="block text-jnl-teal-medium text-sm lg:mt-2 py-2 lg:inline-block lg:mt-0
                hover:text-jnl-teal-dark transition ease-in duration-300 uppercase font-light"
              >
                {option.text}
              </Link>
            )
          })}
          <a
            className="block text-sm text-jnl-red-medium lg:mt-2 py-2 lg:inline-block lg:mt-0 mr-4
            hover:text-jnl-teal-dark transition ease-in duration-300 uppercase font-thin"
            href="https://www.tryinteract.com/share/quiz/5eb07641bed8430014a171cd"
          >
            What is Your Homeschool Style?{clickIcon()}
          </a>
        </div>
        {/* <!--/Mobile Menu--> */}
      </div>
    </nav>
  )
}

export default Nav
