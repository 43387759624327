import React from "react"
import { Link } from "gatsby"
import FeedIcon from "../components/icons/rssfeed-icon"

const Footer = ({ className }) => {
  return (
    <footer className={`bg-gray-800 relative z-10 ${className}`}>
      <div className="container mx-auto px-8 pb-4">
        <div className="w-full flex flex-col md:flex-row divide-x divide-gray-600 py-6">
          <div className="flex-1 px-2 mb-6">
            <Link className="" to="/#">
              <img
                className="h-auto w-48"
                src="/joyful-noise-learning-logo.svg"
                alt="Joyful Noise Learning Logo "
              />
            </Link>

            <div className="text-sm text-gray-500 ml-2">
              © {new Date().getFullYear()}
              {` `}
              Joyful Noise Learning
            </div>
          </div>

          <div className="flex-1 px-2 space-y-4">
            <p className="uppercase text-gray-500 text-sm md:mb-6">Links</p>
            <ul className="list-reset mb-6">
              <li className="mt-2">
                <Link
                  to="/blog"
                  className="no-underline hover:underline text-gray-300
                             hover:text-gray-500"
                >
                  Blog
                </Link>
              </li>
              <li className="mt-2">
                <a
                  href="/rss.xml"
                  className="no-underline hover:underline text-gray-300
                             hover:text-gray-500"
                  title="RSS Feed"
                >
                  RSS Feed <FeedIcon size="4" />
                </a>
              </li>
              <li className="mt-2">
                <Link
                  to="/printables"
                  className="no-underline hover:underline text-gray-300
                             hover:text-gray-500"
                  title="Homeschool Resources"
                >
                  Printables
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="/ready-set-homeschool"
                  className="no-underline hover:underline text-gray-300
                             hover:text-gray-500"
                  title="Homeschool Resources"
                >
                  Guide for New Homeschoolers
                </Link>
              </li>
              <li className="mt-2">
                <a
                  href="https://payhip.com/JoyfulNoiseLearning"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline hover:underline text-gray-300
                             hover:text-gray-500"
                  title="Homeschool Resources"
                >
                  Online Store
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-1 px-2">
            <p className="uppercase text-gray-500 text-sm md:mb-6">Legal</p>
            <ul className="list-reset mb-6">
              <li className="mt-2">
                <Link
                  to="/terms"
                  className="no-underline hover:underline text-gray-300
                             hover:text-gray-500"
                >
                  Terms
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="/privacy-policy"
                  className="no-underline hover:underline text-gray-300
                             hover:text-gray-500"
                >
                  Privacy
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex-1 px-2">
            <p className="uppercase text-gray-500 text-sm md:mb-6">
              Social Media
            </p>
            <ul className="list-reset mb-6">
              <li className="mt-2">
                <a
                  href="https://www.youtube.com/c/joyfulnoiseliving"
                  className="no-underline hover:underline text-gray-300
                             hover:text-gray-500"
                >
                  YouTube
                </a>
              </li>
              <li className="mt-2">
                <a
                  href="https://instagram.com/joyfulnoisemama"
                  className="no-underline hover:underline text-gray-300
                             hover:text-gray-500"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
